// RevenueInfluencerPDF.js
import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'center',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: '10px',
    padding: '10px',
  },
  tableCol: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: '10px',
    padding: '10px',
  },
});

const RevenueInfluencerPDF = ({ data }) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Revenue Influencer Data</Text>
        <View style={styles.table}>
          <View style={[styles.tableRow, { backgroundColor: 'whitesmoke' }]}>
            <View style={[styles.tableColHeader, { width: '20%' }]}>
              <Text style={{ fontWeight: 'bold', color: '#47525A' }}>Name</Text>
              <Text>Username</Text>
            </View>
            <View style={[styles.tableColHeader, { width: '35%' }]}>
              <Text style={{ fontWeight: 'bold', color: '#47525A' }}>Email</Text>
              <Text>Phone</Text>
            </View>
            <View style={[styles.tableColHeader, { width: '10%' }]}>
              <Text style={{ fontWeight: 'bold', color: '#47525A' }}>Revenue</Text>
              <Text>Status</Text>
            </View>
            <View style={[styles.tableColHeader, { width: '35%' }]}>
              <Text style={{ fontWeight: 'bold', color: '#47525A' }}>Payment ID</Text>
              <Text>Bank Account</Text>
            </View>
          </View>
          {data.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={[styles.tableCol, { width: '20%' }]}>
                <Text style={{ fontWeight: 'bold', color: '#47525A' }}>{item.name}</Text>
                <Text>{item.username}</Text>
              </View>
              <View style={[styles.tableCol, { width: '35%' }]}>
                <Text style={{ fontWeight: 'bold', color: '#47525A' }}>{item.email}</Text>
                <Text>{item?.phoneNumber || '--'}</Text>
              </View>
              <View style={[styles.tableCol, { width: '10%' }]}>
                <Text style={{ fontWeight: 'bold', color: '#47525A' }}>${item.revenue}</Text>
                <Text>{item?.payoutStatus || '--'}</Text>
              </View>
              <View style={[styles.tableCol, { width: '35%' }]}>
                <Text style={{ fontWeight: 'bold', color: '#47525A' }}>{item?.paymentId || '--'}</Text>
                <Text>{item?.paymentType || '--'}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default RevenueInfluencerPDF;
