import React, { useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import RevenueInfluencerPDF from './RevenueInfluencerPdf';
import { useLocation } from 'react-router-dom';

const RevenueInfluencerPdfView = ({ }) => {
    const { state } = useLocation();
    useEffect(() => {
        console.log({ listRevenue: state.listRevenue })
    }, [state])


    return < div >
        {/* Your existing component content */}
        {/* Add a button to trigger PDF download */}
        <PDFViewer style={{ width: '100%', minHeight: '100vh' }}>
            <RevenueInfluencerPDF data={state.listRevenue} />
        </PDFViewer>
    </div >
};

export default RevenueInfluencerPdfView;
